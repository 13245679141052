import { debounce } from '@s-libs/micro-dash';
import { useEffect } from 'react';
import { track } from './track';

const useScrollTracking = () => {
  const scrollPct = () =>
    (window.scrollY / (document.body.scrollHeight - window.innerHeight)).toFixed(2);

  const setupScrollTrackers = () => {
    document.addEventListener('track', trackEvent, { passive: true });
    window.addEventListener('scroll', throttledTrackScroll, { passive: true });
  };

  const tearDownScrollTrackers = () => {
    document.removeEventListener('track', trackEvent);
    window.removeEventListener('scroll', throttledTrackScroll);
  };
  const trackEvent = async (event) => track(event.detail.eventName, event.detail);

  const trackScroll = async () => track('scroll', { label: 'scrollPct', value: scrollPct() });
  const throttledTrackScroll = debounce(trackScroll, 200) as EventListener;

  useEffect(() => {
    setupScrollTrackers();

    return () => tearDownScrollTrackers();
  });
};

export default useScrollTracking;
