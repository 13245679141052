import vtRequestId from '@nrdy-marketing-engine/api-fetch/src/vt-request-id';
import { TrackEventArgsType, TrackEventNameType } from '../@types/schema';
import {
  compactObject,
  customParams,
  domParams,
  leadFormParams,
  pageParams,
  searchParams,
  windowParams,
} from './param-utils';

export const getRequestId = (args) => {
  return args.request_id || vtRequestId();
};

const transformParams = (eventName: TrackEventNameType, args: TrackEventArgsType) => {
  const search_params = searchParams(args);

  return compactObject({
    course_search: Object.keys(search_params).length ? search_params : null,
    customEventData: customParams(eventName, args),
    domEventData: domParams(args),
    leadFormEventData: args.leadFormName ? leadFormParams(args) : null,
    page: pageParams(args),
    pageRequestId: getRequestId(args),
    window: windowParams(),
  });
};

export default transformParams;
