import useThemeContext from './use-theme-context';

export enum Underlay {
  dark = 'dark',
  light = 'light',
}

/**
 * describes the underlay theme info for any component that is rendered within our theme components
 **/
const useUnderlay = () => {
  const { theme } = useThemeContext();

  return theme.isDarkBackground ? Underlay.dark : Underlay.light;
};

export default useUnderlay;
