import { FormContext, PanelContext } from '@nrdy-marketing-engine/form-context';
import { useContext, useEffect, useRef } from 'react';
import { TrackEventNameType } from '../@types/schema';
import { track } from './track';

export type DomTrackingArgs = Record<string, string>;

const argsByAction = (event, args?: DomTrackingArgs) => {
  const target = event.target;
  const currentTarget = event.currentTarget;
  const className = target.className;

  return {
    click: {
      className,
      target: currentTarget.tagName,
      text: args?.textContent || currentTarget.textContent,
    },
    blur: {
      className,
      fieldName: args?.name || target.name,
      target: args?.target || target.tagName,
      fieldValue: args?.value || target.value,
    },
    load: {
      target: 'page',
    },
  };
};

const useDomTracking = (eventName: TrackEventNameType, args?: DomTrackingArgs) => {
  const domTrackable = useRef<any>();
  const panelArgs = useContext(PanelContext);
  const formArgs = useContext(FormContext);

  useEffect(() => {
    const element = domTrackable.current;

    const trackEvent = (event, eventName) => {
      track(eventName, {
        ...argsByAction(event, args)[eventName],
        ...panelArgs,
        ...formArgs,
      });
    };

    if (element) {
      element.addEventListener(eventName, (event) => trackEvent(event, eventName), {
        passive: true,
      });
    }

    return () => element?.removeEventListener(eventName, (event) => trackEvent(event, eventName));
  }, [panelArgs.stepName]);

  return domTrackable;
};

export default useDomTracking;
