import { NRMetadata } from '@nrdy-marketing-engine/tracking/@types/schema';

export const newRelicTrack = (
  eventName: string,
  metadata: NRMetadata,
  transformed: { [x: string]: any }
) => {
  window['newrelic']?.addPageAction(eventName, {
    ...transformed,
    customEventData: {
      ...transformed?.customEventData,
      ...metadata,
    },
  });
};
