import { useEffectOnce } from 'react-use';
import { track } from './track';
import { page } from './trackers/segment-tracker';

const usePageLoadTracking = () => {
  /*
    NOTE: This runs on the load event only once, and if we ever want to move towards
    a more SPA centered application we might want to make sure this tracks on each
    page change and consider that a load
  */

  const listener = () => {
    track('loaded', { target: 'page' });
    page({});
  };

  const setupLoadTracker = () => {
    if (document.readyState === 'complete') listener();
    else window.addEventListener('load', listener, { passive: true });
  };

  const tearDownLoadTracker = () => window.removeEventListener('load', listener);

  useEffectOnce(() => {
    setupLoadTracker();

    return () => tearDownLoadTracker();
  });
};

export default usePageLoadTracking;
