import { PageEventParams } from '@varsitytutors/event-tracker';
import { TrackEventArgsType } from '../@types/schema';
import { getCategory, pageParams } from './param-utils';
import { getRequestId } from './transform-params';

export function buildSegmentPageEvent(args: TrackEventArgsType): PageEventParams {
  return {
    name: undefined,
    category: undefined,
    properties: {
      ...pageParams(args),
      path: location?.pathname,
      title: document?.title,
      referrer: document?.referrer,
      search: location?.search,
      application: getCategory(args),
      pageRequestId: getRequestId(args),
    },
  };
}
export default buildSegmentPageEvent;
