import { v4 as uuid } from 'uuid';
import Cookies from 'js-cookie';

type VtVisitorId = {
  (): string;
  uuid: string;
};

const vtVisitorId = <VtVisitorId>function () {
  const existingVisitorId = vtVisitorId.uuid || Cookies.get('visitor_id');
  const domainVal = (window.location.hostname || '').replace('www', '');

  if (existingVisitorId) {
    return existingVisitorId;
  }

  vtVisitorId.uuid = uuid();
  Cookies.set('visitor_id', encodeURIComponent(vtVisitorId.uuid), {
    domain: domainVal,
    samesite: 'lax',
    path: '/',
    secure: true,
    expires: 30,
  });

  return vtVisitorId.uuid;
};

export default vtVisitorId;
