import { Action, ActionTracker } from '@varsitytutors/event-tracker';
import { TrackEventNameType } from '../../@types/schema';

interface ActionTrackerInterface {
  track(params: Action): Promise<Response>;
}

const defaultTracker: ActionTrackerInterface = new ActionTracker({
  applicationId: process.env.NEXT_PUBLIC_ACTIONTRACKER_APPID,
  endpoint: process.env.NEXT_PUBLIC_ACTIONTRACKER_ENDPOINT,
});

export async function trackActionTracker(eventName: TrackEventNameType, transformed: Action) {
  try {
    await defaultTracker.track(transformed);
  } catch (err) {
    throw new Error(`Failed to track event ${eventName} - ${err}`);
  }
}
