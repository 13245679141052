import { v4 as uuid } from 'uuid';

type VtRequestId = {
  (): string;
  uuid: string;
};

const vtRequestId = <VtRequestId>function () {
  // store uuid as a singleton for the life of the page
  vtRequestId.uuid = vtRequestId.uuid || uuid();
  return vtRequestId.uuid;
};

export default vtRequestId;
