import { useEffect, useState } from 'react';

/**
 * hook to trigger the display of the first lead form
 **/
export const useShowFirstLeadForm = () => {
  const [trigger, setTrigger] = useState(0);

  const showFirstLeadForm = () => setTrigger(trigger + 1);

  // Select the first container with an id (this is to avoid selecting banners) and a data-container-key attribute (to select NSP containers)
  // Under that select the first button with a data-trigger="get-started" attribute
  const buttonToClick = () =>
    document.querySelector<HTMLButtonElement>(
      'div[data-container-key][id] button[data-trigger="get-started"]'
    );

  useEffect(() => {
    // If the trigger is 0, do not show the form
    if (trigger === 0) return;

    const button = buttonToClick();
    if (!button) return;

    button?.click();
  }, [trigger]);

  return { showFirstLeadForm };
};

export default useShowFirstLeadForm;
