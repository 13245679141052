'use client';

import { Button } from '@nrdy-marketing-engine/button';
import { useShowFirstLeadForm } from '@nrdy-marketing-engine/use-show-first-lead-form';

import * as styles from './index.css';

const CTAButton = ({ href, action, target, ctaText, themeVersion }) => {
  const { showFirstLeadForm } = useShowFirstLeadForm();

  const buttonVariant = themeVersion ? styles.getStartedButtonV1 : styles.getStartedButtonV2;

  const clickGetStarted = () => {
    if (action === 'leadform') {
      showFirstLeadForm();
    }
  };

  return (
    <Button
      className={buttonVariant}
      color="secondary"
      href={href}
      onClick={clickGetStarted}
      target={target}
      variant="contained"
    >
      {ctaText}
    </Button>
  );
};

export default CTAButton;
