import { EventEmitterContext } from '@nrdy-marketing-engine/eventing';
import useUnderlay, { Underlay } from '@nrdy-marketing-engine/theming/src/use-underlay';
import { useDomTracking } from '@nrdy-marketing-engine/tracking';
import { ComponentProps, useCallback, useContext } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from './button';

export const OPEN_FORM = 'OPEN_FORM';
export const CLICK = 'CLICK';

const disabledState =
  'disabled:bg-platinum disabled:text-disabled-control-color disabled:border-transparent';

const primaryButton = (underlay) =>
  ({
    light: twMerge(
      'bg-primary text-primary-contrast border-transparent',
      // hover state
      'hover:text-primary hover:bg-white hover:border-primary',
      // focus state
      'focus:bg-primary focus:text-white focus:outline-white',
      disabledState
    ),
    dark: twMerge(
      'bg-primary text-white border-transparent',
      // hover state
      'hover:text-primary hover:bg-white hover:border-primary',
      // focus state
      'focus:bg-primary focus:text-white focus:outline-white',
      disabledState
    ),
  }[underlay]);

const secondaryButton = (underlay) =>
  ({
    light: twMerge(
      'bg-white text-primary border-primary',
      // focus state
      'focus:bg-primary focus:text-white focus:outline-white',
      // disabled state
      disabledState + ' disabled:border-disabled-control-border'
    ),
    dark: twMerge(
      'bg-transparent border-white text-white',
      // hover state
      'hover:text-primary hover:bg-white',
      // focus state
      'focus:bg-white focus:text-primary focus:outline-primary',
      // disabled state
      disabledState
    ),
  }[underlay]);

const buttonClasses = ({
  variant,
  className,
  underlay,
}: Partial<ComponentProps<typeof Button>> & { underlay: Underlay }) => {
  const base = twMerge(
    'relative rounded-full text-center',
    'hover:brightness-110 hover:shadow-button',
    'focus:outline-offset-[-4px] focus:outline-2 focus:outline',
    'active:brightness-95 py-2 px-8 font-semibold border-x border-y'
  );

  const classesByVariant = {
    primary: primaryButton(underlay),
    secondary: secondaryButton(underlay),
  };

  const classes = twMerge(base, classesByVariant[variant], className);

  return classes;
};

const scrollToTarget = (target) => {
  target = target.replace('#', '').replace('.', '');
  const element = document.getElementById(target) || document.getElementsByClassName(target)[0];

  if (!element) return;

  element.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

const useButton = ({
  className = '',
  loading = false,
  onClick,
  target,
  text,
  trigger,
  variant = 'primary',
}: Partial<ComponentProps<typeof Button>>) => {
  const underlay = useUnderlay();
  const classes = buttonClasses({ variant, className, underlay });
  const clickTrackable = useDomTracking('click');
  const eventEmitter = useContext(EventEmitterContext);
  const transitionClasses = twMerge(
    'opacity-100',
    loading && 'opacity-0 duration-500 transition-opacity'
  );

  const onButtonClick = useCallback(
    (event) => {
      const getStartedTrigger = trigger === 'get-started';

      if (getStartedTrigger) {
        eventEmitter.emit(OPEN_FORM, { text, variant, className });
      }

      eventEmitter.emit(CLICK, { text, variant, className, target: 'BUTTON' });

      onClick && onClick(event);

      target && scrollToTarget(target);
    },
    [className, eventEmitter, trigger, onClick, text, variant, target]
  );

  return {
    classes,
    clickTrackable,
    onButtonClick,
    transitionClasses,
  };
};

export default useButton;
