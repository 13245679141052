import { TrackEventArgsType, TrackEventNameType } from '../@types/schema';

import { trackActionTracker } from './trackers/action-tracker';
import { newRelicTrack } from './trackers/newrelic-tracker';
import { trackSegmentTracker } from './trackers/segment-tracker';
import transformParams from './transform-params';

/**
 * Tracks an event, sends information to newrelic, action-tracker and segment
 */
export const track = async (eventName: TrackEventNameType, args: TrackEventArgsType) => {
  try {
    const { newrelicMetadata, ...restOfArgs } = args;
    const transformed = transformParams(eventName, restOfArgs);

    newRelicTrack(eventName, newrelicMetadata, transformed);
    const promises = [
      trackActionTracker(eventName, transformed),
      trackSegmentTracker(eventName, transformed),
    ];
    return Promise.all(promises);
  } catch (err) {
    window['newrelic']?.noticeError(err);
    throw new Error(`Failed to track event ${eventName} - ${JSON.stringify(args)} - ${err}`);
  }
};
