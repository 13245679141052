import { isObject, omitBy } from '@s-libs/micro-dash';
import { TrackEventNameType } from '../@types/schema';
import vtVisitorId from './visitor-ids';

const isNullOrUndefined = (value) => value === null || value === undefined;
export const compactObject = (obj) => omitBy(obj, isNullOrUndefined);
const limitItem = (item) => {
  if (!item) return item;

  // stringify an object if it's an object
  const str = isObject(item) ? JSON.stringify(item) : item;

  return str?.length && str.length > 200 && str.slice ? str.slice(0, 197).concat('...') : str;
};

export const getCategory = (args) => {
  return limitItem(args.category) || 'nme';
};

/**
 * Creates the custom params based on the values passed into args
 *
 * docs to expected values (previous tracking package):  https://github.com/varsitytutors/llt-action-tracker/blob/master/schemas/pages/interaction/1-0-0.json#L58
 *
 * docs to expected values (current tracking package): https://github.com/varsitytutors/mex-packages/blob/main/packages/event-tracker/src/types/interaction-schema.ts
 */
export const customParams = (eventName: TrackEventNameType, args) => {
  return compactObject({
    action: eventName,
    category: getCategory(args),
    label: limitItem(args.label),
    value: limitItem(args.value),
  });
};

/**
 * Create the DOM params based on the values passed into args
 */
export const domParams = (args) => {
  const includeDataAttributes =
    typeof args?.dataAttributes == 'object' && Object.keys(args.dataAttributes).length > 0;

  return omitBy(
    {
      classes: args.className && args.className.split(' '),
      data: includeDataAttributes && args.dataAttributes,
      target: args.target || 'document',
      name: args.fieldName,
      value: args.fieldValue,
      href: args.href,
      text: args.text,
    },
    (value) => !value
  );
};

/**
 * Gets the content of a meta tag from the page
 * @param {String} name - Name of meta tag
 * @return {String|null} The content of the meta tag
 */
const getMeta = (name: string) => {
  const meta = document.querySelector(
    `meta[name="${name}"], meta[property="${name}"]`
  ) as HTMLMetaElement;

  return meta ? meta.content : null;
};

/**
 * Create the DOM params based on the values passed into args
 */
export const leadFormParams = (args) =>
  compactObject({
    id: args.leadFormName,
    panel: args.stepName != null ? args.stepName : null,
  });

/**
 * Create the page params based on the values passed into args
 */
export const pageParams = (args) =>
  compactObject({
    url: location.href.split('#')[0],
    visitorId: args.visitor_id || vtVisitorId(),
    divisionId: args.division_id || 'VT::VT',
    userAgent: navigator.userAgent,
    contentVersion: args.content_version || getMeta('content-version'),
    nspSlug: getMeta('nsp-slug') || '',
    nspSlugType: getMeta('nsp-slug-type') || '',
    tests:
      args.ab_test_experiment && args.ab_test_variant
        ? [{ experimentId: args.ab_test_experiment, variantId: args.ab_test_variant }]
        : null,
    clientUuid: args['client_uuid'] || null,
    entity: args['entity'] || null,
    sessionId: args['session_id'] || null,
    userId: args['user_id'] || null,
  });

/**
 * Creates the search params based on the values passed into args
 */
export const searchParams = (args) =>
  compactObject({
    query: typeof args?.query === 'string' ? args.query : null,
    filters: typeof args?.filters === 'object' ? args.filters : null,
    page: typeof args?.page === 'object' ? args.page : null,
    result_uuids: Array.isArray(args?.result_uuids) ? args.result_uuids : null,
  });

export const windowParams = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
  visibleElements: [],
});
