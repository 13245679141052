import {
  PageEventParams,
  SegmentTracker,
  TrackEventParams,
  TrackerResponse,
} from '@varsitytutors/event-tracker';
import { TrackEventArgsType, TrackEventNameType } from '../../@types/schema';
import buildSegmentPageEvent from '../build-page-event';

interface SegmentTrackerInterface {
  page: (params: PageEventParams) => Promise<TrackerResponse>;
  track(params: TrackEventParams): Promise<TrackerResponse>;
}

let segmentTrackerInstances: Record<string, SegmentTrackerInterface> = {};

const initInstance = (
  segmentWriteKey: string,
  params?: Record<string, unknown>
): SegmentTrackerInterface => {
  const currentInstance = segmentTrackerInstances[segmentWriteKey];
  if (currentInstance) return currentInstance;
  const newInstance = new SegmentTracker(segmentWriteKey, {
    ...params,
  });
  segmentTrackerInstances[segmentWriteKey] = newInstance;
  return newInstance;
};

const defaultSegmentTracker: SegmentTrackerInterface = initInstance(
  process.env.NEXT_PUBLIC_SEGMENT_LLT_ACTIONTRACKER_ID,
  { excludeEvents: ['apiFetch', 'scroll'] }
);

export async function trackSegmentTracker(
  eventName: TrackEventNameType,
  transformed: { [x: string]: any }
) {
  try {
    return await defaultSegmentTracker.track({
      eventName,
      properties: { ...transformed, applicationId: process.env.NEXT_PUBLIC_ACTIONTRACKER_APPID },
    });
  } catch (err) {
    throw new Error(`Failed to track event ${eventName} - ${err}`);
  }
}

export const page = async (args: TrackEventArgsType) => {
  try {
    return defaultSegmentTracker.page(buildSegmentPageEvent(args));
  } catch (err) {
    throw new Error(`Failed to track page - ${JSON.stringify(args)} - ${err}`);
  }
};
